import styled from "styled-components";
import { device } from "@theme";

export const SectionWrap = styled.div`
padding-top: 89px;
padding-bottom: 89px;
@media ${device.medium}{
    padding-top: 72px;
    padding-bottom: 72px;
}
@media ${device.small}{
    padding-top: 53px;
    padding-bottom: 53px;
}
`;


export const SectionTitle = styled.div`
padding-top: 29px;
padding-bottom: 29px;
    margin-bottom: 60px;
    @media ${device.medium}{
        margin-bottom: 50px;
    }
`;



export const ListGroupWrap = styled.div`
    margin-bottom: 60px;
    @media ${device.medium}{
        margin-bottom: 50px;
    }
    @media ${device.small}{
        margin-bottom: 30px;
    }
`;
import imageOne from "@data/images/team/gangli.jpg";


export const data = [
	{
		id: "gang-li",
		name: "Gang Li",
		designation: "Lab Director, Deakin",
		position: "head",
		image: imageOne,
		socials: {
            "scholar": "https://scholar.google.com/citations?user=dqwjm-0AAAAJ",
            "orcid": "https://github.com/tuliplab",
            "web": "/members/gangli"
        }

		
	}
]
import * as React from "react";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/header-one";
import Footer from "@layout/footer/footer-one";
// import PageHeader from "@components/pageheader";
import Banner from "@components/banners/Ai";
// import Cta from "@containers/global/cta-area/section-one";
import SectionOne from '@containers/members/gangli'

const DividersPage = ({ pageContext, location }) => {
	return (
		<Layout location={location}>
			<Seo title="Director" />
			<Header />
			<Banner
				pageContext={pageContext}
				location={location}
				title="Gang Li"
			/>
			<main className="site-wrapper-reveal">
				<SectionOne />
				{/* <Cta /> */}
			</main>
			<Footer />
		</Layout>
	)
}

export default DividersPage